<template>
  <v-content v-if="!success">
    <v-container fill-height>
      <v-layout align-center justify-center class="signin_layout">
        <div class="signin_box_wrapper">          
          <div class="signin_box_img"></div>
          <div class="signin_box">
            <div class="signin_header">
              <img src="@/assets/img/bici/logo.png" width="40"/>
              <div class="signin_title">
                <small>China1 | HCCP</small>
                <span>Signed Out</span>
              </div>
            </div>

            <div class="register" style="text-align: left; height: 17rem;">
              You have signed out from JinSolution successfully.
              You can sign in directly again without user account information.
            </div>

            <div class="button_wrapper">
              <v-btn color="warning" @click="reenter()">Re-enter</v-btn>
            </div>
            
            <div class="description">
              Optimized for viewing in 
              <a href="https://www.google.com/chrome/?brand=CHBD&gclid=CjwKCAjwiMj2BRBFEiwAYfTbClJ4Iht0So5ri1TexRLdt6qK6Z_26bkFyF3nyzLsIvC_RYWydvVAuBoCgp8QAvD_BwE&gclsrc=aw.ds" target="_blank">
              Chrome
              </a>
              <br>to provide better reporting service.
            </div>     
          </div>
        </div>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import '@/assets/stylus/ui/component/_signin.styl'

import { mapState, mapActions } from 'vuex'
import __C from '../primitives/_constant_'
import ServiceRoutes from '@/mixins/service.routers.mixin'


export default {
  name: 'signin',
  mixins: [
    ServiceRoutes
  ],
  data: () => ({
    errMsg: '',
    accountService: null,
    username: '',
    password: '',
    submitted: false,
    success: false,
    incorrect: {
      visibility: 'hidden'
    },
  }),
  created() {
    this.success = false
  },
  watch: {
    username(v) {
       if (v == '') {
         this.incorrect.visibility='hidden'
       }
    },
    password(v) {
       if (v == '') {
         this.incorrect.visibility='hidden'
       }
    }
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['status']),
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
  },
  methods: {
    ...mapActions(__C.STORE_NAMESPACE.ACCOUNT, ['signin', 'signout']),
    ...mapActions(__C.STORE_NAMESPACE.APPLICATION, ['initialize']),

    reenter() {
      this.$router.push({ path: '/' })
    },
  }
}
</script>